export default {
    global: {
        outer: 'mx-auto w-full',
        inner: 'relative ',
        label: 'block text-sm font-medium leading-6 text-gray-900',
        input: 'block w-full bg-white rounded-md border-0 py-1.5 px-3 h-9 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-orange-600 focus:ring-orange-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100',
        help: 'mt-1 text-sm text-gray-500',
        messages: 'list-none p-0 text-sm text-red-600',
        message: 'mt-2 ',
        prefixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 left-3 pointer-events-none',
        suffixIcon: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 w-10',
    },
    textarea: {
        input: 'sm:h-32 outline-orange-600',
    },
    checkbox: {
        legend: 'block text-sm font-medium leading-6 text-gray-900',
        wrapper: '$reset flex items-center gap-2 cursor-pointer text-blue',
        inner: '$reset',
        decorator: 'rounded-full',
        decoratorIcon: 'w-5 p-[5px]',
        label: 'cursor-pointer ',
        input: '$reset h-4 w-4',
    },
    select: {
        input: 'appearance-none',
        selectIcon: 'absolute top-1/2 -translate-y-1/2 w-7 right-3 pointer-events-none',
    },
    file: {
        wrapper: 'relative',
        input: '$reset absolute top-0 left-0 bottom-0 right-0 opacity-0',
        inner: 'w-full',
        prefixIcon: '$reset absolute top-1/2 -translate-y-1/2 w-6 left-0 pointer-events-none',
        outer: 'flex items-center',
        noFiles: 'pl-10',
        fileName: 'pl-10',
        // fileRemove: 'top-9 right-0 absolute text-red flex gap-2 items-center hover:text-red-dark',
        fileRemoveIcon: ' w-[16px]',
        help: 'pointer-events-none	',
    },
    radio: {
        wrapper: '$reset flex items-center gap-2 py-1',
        inner: '$reset',
        decorator: 'rounded-full',
        decoratorIcon: 'w-5 p-[5px]',
        label: 'cursor-pointer ',
        input: '$reset',
    },
};
