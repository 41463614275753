import formkitTheme from "@/formkitTheme";

export const TRANSITIONS = {
    overlay: {
        enterFromClass: 'opacity-0',
        enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
        leaveActiveClass: 'transition-opacity duration-150 ease-linear',
        leaveToClass: 'opacity-0',
    },
};

export default {
    autocomplete: {
        root: ({props}) => ({
            class: [
                'relative inline-flex w-full text-base',
                {
                    'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
                },
                {'w-full': props.multiple},
            ],
        }),
        container: {
            class: [
                'm-0 list-none cursor-text overflow-hidden flex items-center flex-wrap w-full',
                'px-3 py-2 gap-2 border-gray-300 border rounded-md',
                'font-sans text-base text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-300 dark:border-blue-900/40  transition duration-200 ease-in-out appearance-none rounded-md',
                '',
            ],
        },
        inputtoken: {
            class: ['py-0.375rem px-0', 'flex-1 inline-flex'],
        },
        input: ({props}) => ({
            class: [
                formkitTheme.global.input,
                ' transition-colors duration-200 appearance-none focus:placeholder-gray-light',
                {'': props.dropdown},
                {
                    'font-sans text-base text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 p-3 border border-gray-300 dark:border-blue-900/40 ] focus:outline-none':
                        !props.multiple,
                    'font-sans text-base text-gray-700 dark:text-white/80 border-0 outline-none bg-transparent m-0 p-0 shadow-none rounded-none w-full': props.multiple,
                },
            ],
        }),
        token: {
            class: ['py-1 px-2 mr-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white/80 rounded-full', 'cursor-default inline-flex items-center'],
        },
        loadingIcon: {
            root: 'hidden',
        },
        dropdownbutton: {
            root: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none !pointer-events-auto flex-center',
        },
        panel: {
            class: ['bg-white text-gray-700 border rounded-md shadow-lg !max-h-100 ', 'overflow-y-scroll', 'bg-white text-gray-700 border rounded-md shadow-lg', 'dark:bg-gray-900 dark:text-white/80'],
        },
        list: 'py-3 px-4 list-none m-0',
        item: ({context}) => ({
            class: [
                'cursor-pointer font-normal text-sm overflow-hidden relative whitespace-nowrap leading-[36px] ',
                'm-0 px-3 -mx-3 border-0 transition-shadow duration-200 rounded-none',
                'dark:text-white/80 dark:hover:bg-gray-800',
                'hover:text-white hover:bg-orange-500',
                {
                    'text-gray-700': !context.focused && !context.selected,
                    'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused && !context.selected,
                    'bg-orange-500 text-white dark:bg-blue-400 dark:text-white/80': context.focused && context.selected,
                    'bg-orange-500 text-white dark:bg-blue-300 dark:text-white/80': !context.focused && context.selected,
                },
            ],
        }),
        itemgroup: {
            class: ['m-0 p-3 text-gray-800 bg-white font-bold', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
        },
        transition: TRANSITIONS.overlay,
    },
};
